/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ENTITY_NAME } from './ENTITY_NAME';
import type { UserEntity } from './UserEntity';
export type ChangelogEntity = {
  id: number;
  tenantId: number;
  userId: number;
  action: ChangelogEntity.action;
  entityName: ENTITY_NAME;
  entityId: string;
  /**
   * Entity before changes (JSON)
   */
  before?: string | null;
  /**
   * Entity after changes (JSON)
   */
  after?: string | null;
  createdAt: string;
  user?: UserEntity;
};
export namespace ChangelogEntity {
  export enum action {
    CREATE = 'create',
    UPDATE = 'update',
    DELETE = 'delete',
  }
}

